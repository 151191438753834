import { useEffect } from 'react'

export default function useGA3() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-YH4YFTKYGP"
    script.type = "text/plain"
    script.setAttribute("data-usercentrics", "Google Analytics")

    const scriptDataLayer = document.createElement('script')
    scriptDataLayer.setAttribute("data-usercentrics", "Google Analytics")
    scriptDataLayer.innerHTML = 
`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-YH4YFTKYGP');
gtag('config', 'AW-350833759');`
  
    document.head.appendChild(script)
    document.head.appendChild(scriptDataLayer)
  
    return () => {
      document.head.removeChild(script)
      document.head.removeChild(scriptDataLayer)
    }
  }, []);
}