import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import useUsercentrics from "../hooks/useUsercentrics"
import useGoogleAnalytics4 from "../hooks/useGoogleAnalytics4"

import Header from "./header"
import "./layout.css"

import { ReactComponent as IconLogo } from '../assets/svg/verhaltenstherapie-haidhausen-icon.svg'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useUsercentrics()
  useGoogleAnalytics4()
  
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <div className="relative">
          <div className="w-full h-96">
            <StaticImage className="w-full h-full" src="../assets/images/therapie-hilft-kontakt.jpg" alt="Zwei einladende blaue Ohrenseseel in einer hellen Altraumwohnung" />
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center bg-white/60">
              <div className="w-full text-center">
                <h3 className="mb-6 md:text-4xl text-primary-font">Psychotherapie hilft.</h3>
                <Link to="/kontakt" className="text-white rounded-2xl btn btn--primary">Jetzt Termin vereinbaren</Link>
              </div>
            </div>
          </div>
        </div>
      <footer className="pt-16 pb-12 bg-gray-50 sm:pt-20 md:pt-24 xl:pt-32 sm:pb-20">
        <div className="px-4 mx-auto divide-y divide-gray-200 max-w-7xl sm:px-6 md:px-8">
          <ul className="grid text-sm font-medium sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-x-10 gap-y-14 pb-14 sm:pb-20">
            <li className="row-span-2 space-y-5 sm:col-span-2 md:col-span-1 lg:col-span-2">
              <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">Kontakt</h2>
              <ul className="space-y-4">
                <li>M.Sc. Katharina Stephan | Psychologische Psychotherapeutin (VT)</li>
                <li><address className="not-italic">Comeniusstraße&nbsp;1<br />81667&nbsp;München</address></li>
                <li>E-Mail: <a href="mailto:stephan@verhaltenstherapie-haidhausen.de">stephan@verhaltenstherapie-haidhausen.de</a></li>
                <li>Telefon: <a href="tel:+491639272103">+49 163 927 21 03</a></li>
              </ul>
            </li>
            <li className="row-span-2 space-y-5 md:col-span-1 lg:col-span-2">
              <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">Anfahrt</h2>
              <ul className="space-y-4">
                <li><strong>Öffentliche Verkehrsmittel:</strong><br />Rosenheimer Platz (S-Bahn), Ostbahnhof (S-Bahn/U-Bahn/Zug), Max-Weber-Platz (U-Bahn), Wörthstraße (Tram)</li>
                <li><a href="https://goo.gl/maps/hCd67LAT1mqCSAqE7">In Google Maps öffnen</a></li>
              </ul>
            </li>
            <li className="row-span-2 space-y-5 md:col-span-2 lg:col-span-1">
              <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">Freie Therapieplätze</h2>
              <ul className="space-y-4">
                <li>Gerne können Sie einen Termin für ein zeitnahes Erstgespräch in meiner Psychotherapie-Praxis in München vereinbaren.</li>
                <li><Link to="/kontakt">Termin vereinbaren</Link></li>
              </ul>
            </li>
          </ul>
          <ul className="flex justify-center py-2 mx-auto space-x-5">
            <li><Link to="/impressum" className="transition-colors duration-200 hover:text-gray-900">Impressum</Link></li>
            <li><Link to="/datenschutz" className="transition-colors duration-200 hover:text-gray-900">Datenschutz</Link></li>
          </ul>
          <div className="pt-6 text-sm font-light leading-relaxed text-center uppercase md:pt-12 text-primary-font">
            <Link to="/"><IconLogo className="w-16 h-auto mx-auto mb-4 fill-current md:mb-10 text-primary-font" /></Link>
            © {new Date().getFullYear()} Verhaltenstherapie Haidhausen. Alle Rechte vorbehalten.
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
