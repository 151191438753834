import { useEffect } from 'react';

export default function useUsercentrics() {
  const userCentricsId = 'efvxeOYnV'
  
  useEffect(() => {
    const scriptCMP = document.createElement('script')
    scriptCMP.src = 'https://app.usercentrics.eu/latest/main.js'
    scriptCMP.id = userCentricsId
    scriptCMP.type = 'application/javascript'
  
    document.head.appendChild(scriptCMP)
  
    return () => {
      document.head.removeChild(scriptCMP)
    }
  }, []);
}